import * as type from 'constants/action_types/alerts'
import { SET_GLOBAL_MODAL } from 'constants/action_types'
import { ALERTS_LIMIT, DELETE_ALERT_MODAL_NAME } from 'constants/alerts'

import { updateInArray } from 'helpers/arrays'
import {
  getLocalAlertsLastViewedId,
  setLocalAlertsLastViewedId
} from 'helpers/alerts'

import { createNotification } from 'actions/notifications'
import { viewProductInTracker } from 'actions/tracker'
import { viewProductInMyProducts } from 'actions/product'

import Api from 'services/api'

export const setLastViewedAlertId = (alertId = null) => dispatch => {
  if (alertId) {
    setLocalAlertsLastViewedId(alertId)
    dispatch({
      type: type.SET_ALERTS_DATA,
      payload: { lastViewedAlertId: alertId }
    })
  } else {
    const id = getLocalAlertsLastViewedId()

    if (id) {
      dispatch({
        type: type.SET_ALERTS_DATA,
        payload: { lastViewedAlertId: id, lastUnreadAlertId: id }
      })
    }
  }
}

export const getAlerts = ({ initial = false } = {}) => async (
  dispatch,
  getState
) => {
  if (getState().alerts.filters.keys.length === 0) {
    dispatch({
      type: type.SET_ALERTS_DATA,
      payload: { alerts: [], currentPage: 0 }
    })
  } else {
    if (initial) {
      dispatch({
        type: type.SET_ALERTS_DATA,
        payload: { alerts: [], currentPage: 0 }
      })
    }

    const { currentPage, alerts, filters } = getState().alerts
    const page = currentPage + 1

    const params = {
      page,
      ...filters
    }

    if (page === 1) {
      dispatch({ type: type.PENDING_ALERTS_ACTION, payload: 'alertsFetch' })
    }

    dispatch({ type: type.PENDING_ALERTS_ACTION, payload: 'moreAlerts' })

    const res = await Api.getAlerts(params)

    if (res.ok) {
      dispatch({
        type: type.SET_ALERTS_DATA,
        payload: {
          alerts: [...alerts, ...res.data],
          currentPage: page,
          showLoadMore: res.data.length === ALERTS_LIMIT
        }
      })
    } else {
      const action = {
        message: res.error,
        level: 'error',
        title: 'Alerts Load Error'
      }
      dispatch(createNotification(action))
    }
    if (page === 1) {
      dispatch({ type: type.RESET_ALERTS_ACTION, payload: 'alertsFetch' })
    }
    dispatch({ type: type.RESET_ALERTS_ACTION, payload: 'moreAlerts' })
  }
}

export const resetAlertFilter = () => async dispatch => {
  dispatch({ type: type.RESET_ALERTS_FILTER })
}

export const setAlertFilter = (key, value) => async (dispatch, getState) => {
  const { filters } = getState().alerts
  dispatch({
    type: type.SET_ALERTS_DATA,
    payload: {
      filters: {
        ...filters,
        [key]: value
      }
    }
  })
}

export const setAlertRead = (id, read = true) => async (dispatch, getState) => {
  let { alerts } = getState().alerts
  const alert = alerts.find(currentAlert => currentAlert.id === id)
  const read_at = read ? new Date(Date.now()).toISOString() : null

  dispatch({
    type: type.SET_ALERTS_DATA,
    payload: { alerts: updateInArray(alerts, { id }, { ...alert, read_at }) }
  })

  const res = await Api.updateAlert({ id, read })

  if (res.ok) {
    // Refetching to avoid concurrency issues
    alerts = getState().alerts.alerts
    dispatch({
      type: type.SET_ALERTS_DATA,
      payload: {
        alerts: updateInArray(alerts, { id }, res.data)
      }
    })
  } else {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Alerts Error'
      })
    )
    dispatch({ type: type.SET_ALERTS_DATA, payload: { alerts } })
  }
}

export const toggleDeleteAlertModal = alert => dispatch => {
  if (alert) {
    dispatch({
      type: type.SET_ALERTS_DATA,
      payload: { activeAlert: alert }
    })
    dispatch({ type: SET_GLOBAL_MODAL, payload: DELETE_ALERT_MODAL_NAME })
  } else {
    dispatch({ type: SET_GLOBAL_MODAL })
  }
}

export const deleteAlert = () => async (dispatch, getState) => {
  const { alerts, activeAlert } = getState().alerts

  dispatch({
    type: type.SET_ALERTS_DATA,
    payload: { alerts: alerts.filter(a => a !== activeAlert) }
  })

  dispatch(toggleDeleteAlertModal())

  const res = await Api.deleteAlert(activeAlert.id)

  if (!res.ok) {
    dispatch(
      createNotification({
        message: res.error,
        level: 'error',
        title: 'Alerts Error'
      })
    )
    dispatch({ type: type.SET_ALERTS_DATA, payload: { alerts } })
  }
}

export const goToAlertedType = alert => dispatch => {
  const {
    product_alert: { source, country, asin, amazon_seller_account_id }
  } = alert

  const productId = `${country}/${asin}`

  dispatch(setAlertRead(alert.id))

  switch (source) {
    case 'product_tracker':
      dispatch(viewProductInTracker(productId))
      break
    case 'my_products':
      dispatch(viewProductInMyProducts(country, asin, amazon_seller_account_id))
      break
    default:
      dispatch(
        createNotification({
          message: 'Source not defined.',
          level: 'error',
          title: 'Alerts Error'
        })
      )
  }
}
